/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Detailed View of Stories 
Brief :
State Management: Uses useState hook for managing various states like showConclusion, showClick, loading, filledStars, allowClick, and showPopup.

Rendering Story Details: Renders details of a story including its title, author, creation date, summary, content, conclusion, views, and rating.

Interactive Elements: Provides interactive elements such as buttons for sharing, bookmarking, and rating the story.

Conditional Rendering: Implements conditional rendering based on loading state. While the story is loading, a loading screen is displayed.

Star Rating: Allows users to rate the story by clicking stars. It prevents multiple clicks for rating and prompts users to sign up to rate the story if they haven't already.

Sharing Functionality: Provides a share button that uses the Web Share API to share the story link if supported, otherwise, displays a message indicating that the API is not supported.

Date Formatting: Formats the creation date of the story.

Advertisement Display: Includes an advertisement block using the AdsDetailView component.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect } from 'react'
import axios from 'axios';
import MagicPot from '../../../WS-WrittenStories/Assets/Images/Magic pot.WebM'
import Heart from '../../../WS-WrittenStories/Assets/Images/Heart.webp'
import HeartFilled from '../../../WS-WrittenStories/Assets/Images/HeartFilled.webp'
import Share from '../../../WS-WrittenStories/Assets/Images/Share.webp'
import BookmarkFilled from '../../../WS-WrittenStories/Assets/Images/BookmarkFilled.webp'
import Bookmark from '../../../WS-WrittenStories/Assets/Images/Bookmark.webp'
import ShareButton from './ShareButton'
import AdsDetailView from '../../../AM-AplicationManagement/src/JS/Ads/AdsDetailView'
import StarFill from '../../../WS-WrittenStories/Assets/Images/StarFill.webp'
import Star from '../../../WS-WrittenStories/Assets/Images/Star.webp'
import LoginPop from '../../../UM-UserManagement/src/JS/LoginPop'
import LoadingGif from '../../../WS-WrittenStories/Assets/Images/Loading.WebM'
import PlayButtonbtn from '../../../AS-AudioStories/Assets/Images/Play buttonbtn.png'
import StarRate from '../../Assets/Image/StarRate.webp'
import YouTube from 'react-youtube';
import { useLocation, useNavigate } from 'react-router-dom'
import { Add_Favourite, Add_saved, airecommended, Remove_Favourite, Remove_saved, update_story_rating, update_story_views } from '../../../../../Model/WS-WrittenStories/WrittenStories'

function DetailedView({ data, loading, stories, setStories, firstAndLastStoriesNumber, totalStories }) {
    const [showConclusion, setShowConclusion] = useState(false);
    const [showClick, setShowClick] = useState(true);
    const [filledStars, setFilledStars] = useState(Array(5).fill(false));
    const [allowClick, setAllowClick] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const toggleStar = (index) => {
        const storyID = String(data.storyID);
        let lastValueOfI; // Declare i outside the loop
        if (allowClick) {
            const updatedFilledStars = [...filledStars];
            for (let i = 0; i <= index; i++) {

                updatedFilledStars[i] = true;
                lastValueOfI = i + 1; // Store the value of i in lastValueOfI
            }
            const requestBody = {
                story_id: storyID,
                rating: lastValueOfI.toString()
            };
            const requestHeaders = {
                Authorization: `Bearer ${authToken}`,
            };
            axios
                .patch(update_story_rating, requestBody, { headers: requestHeaders })
                .then((response) => {
                    // Handle the API response here
                })
                .catch((error) => {
                    console.error(error); // Handle errors here
                });
            setFilledStars(updatedFilledStars);
            setAllowClick(false); // Prevent further clicks
        }
    };
    const [showSignup, setShowSignup] = useState(false);

    const handleShowSignup = () => {
        setShowSignup(true);
        // Automatically close the signup modal after 3 seconds
        setTimeout(() => {
            setShowSignup(false);
        }, 3000); // 3000 milliseconds (3 seconds)
    };

    const handleCloseSignup = () => {
        navigate('/loginpop');

        // setShowSignup(false);
    };

    const handleShowConclusion = () => {
        setShowConclusion(true);
        setShowClick(false);
    }
    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = () => {
        // setShowPopup(true);
        console.log("tokenmmm:   " + authToken);
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in')
        }
    };
    const [isBookmarked, setIsBookmarked] = useState(false);
    // Initialize bookmarked state for all stories
    const [bookmarkedStories, setBookmarkedStories] = useState(new Array(data.length).fill(false));

    const handleShowBookmark = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in')
            setIsBookmarked(!isBookmarked);
        }
    };
    const unsavedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const savedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const [isHearted, setIsHearted] = useState(false);
    const [heartedStories, setHeartedStories] = useState(new Array(data.length).fill(false));
    const handleShowHeart = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {

            console.log('logged in')
            setIsHearted(!isHearted);
        }
    };
    const unfavouriteStory = (storyID) => {
        storyID = String(storyID);
        console.log(storyID)
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favouriteStory = (storyID) => {
        storyID = String(storyID);

        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const currentURL = window.location.href;

    const handleShareClick = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share Title',
                    text: 'Check out this link!',
                    url: currentURL,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('Web Share API not supported');
            // You can provide a fallback or show your custom share options here
        }
    };
    const authorview = (authorId, author_name) => {
        const formattedauthorName = author_name.replace(/\s+/g, '-');
        navigate(`/authorprofile/${authorId}/${formattedauthorName}`
        );
        window.scrollTo(0, 0);
    }
    const VideoStories = () => {
        navigate(`/videostories`
        );
        window.scrollTo(0, 0);
    }
    const home = () => {
        navigate(`/videostories`
        );
        window.scrollTo(0, 0);
    }
    const [videoId, setVideoId] = useState('3_xV51dZSCI');

    const opts = {
        height: '1164',
        // height:'530',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    const onReady = (event) => {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo(); // For example, pause the video when it's ready
        event.target.playVideo(); // Play the video when it's ready
    };

    // Listview start
    const handleStoryClick = (storyID) => {
        updateStory(storyID);
        ai(storyID)
    }
    const ai = async (storyID) => {
        const url = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/ai/story_status`;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': authToken,

        };
        storyID = String(storyID);
        const body = JSON.stringify({
            "story_id": storyID,
            "story_type": "read",
            "time": "10"
        });

        try {
            const response = await fetch(airecommended, {
                method: 'PUT',
                headers: headers,
                body: body,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };
    const updateStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(update_story_views, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const handleDetailView = (storyID, title) => {
        navigate(`/videostories/${storyID}`,
        );
        window.scrollTo(0, 0);

    }
    const navigateToStory = (storyID, title) => {
        navigate(`/videostories/${storyID}`,
        );
        window.scrollTo(0, 0);
    };

    // Listview end
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    function formatDateMob(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'short' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }


    return (
        <div className='vs-story-detailview'>
            {loading ? (

                <div className='loading'>
                    <div className='load_gif'><img src={LoadingGif} alt='d' /></div>
                </div>

            ) : (
                <div className='vs-detailview'>

                    <div className='ws-detail-authordatebtn'>

                        <div className='vs-detail-authorcdate'>

                            <div className='vs-detail-top-video' onClick={() => home()}>Home&nbsp;&gt;&nbsp;</div>

                            <div className='vs-detail-top-video' onClick={() => VideoStories()}>Video Stories&nbsp;&gt;&nbsp;</div>
                            <div className='vs-detail-Top-title'>{data.title}</div>
                        </div>
                    </div>
                    <div className='ws-detail-authordatebtn'>


                        <div className='ws-detail-authorcdate'>

                            <div className='vs-detail-top-author' onClick={() => authorview(data.authorUserID, data.authorName)}>{data.authorName}&nbsp;-&nbsp;</div>

                            <div className='vs-detail-cdate'>{formatDate(data.creationDate)}</div>
                        </div>

                        <div className='ws-detail-hsb'>
                            <div className='ws-detail-heart' onClick={handleShowHeart}>
                                {
                                    data.favouriteStory ? (
                                        isHearted ? (
                                            <img
                                                onClick={() => favouriteStory(data.storyID)}
                                                src={Heart}
                                                alt='heart'
                                            />

                                        ) : (
                                            <img
                                                onClick={() => unfavouriteStory(data.storyID)}
                                                src={HeartFilled}
                                                alt='heart'
                                            />
                                        )
                                    ) : (
                                        isHearted ?
                                            (
                                                <img
                                                    onClick={() => unfavouriteStory(data.storyID)}
                                                    src={HeartFilled}
                                                    alt='heart'
                                                />
                                            ) : (
                                                <img
                                                    onClick={() => favouriteStory(data.storyID)}
                                                    src={Heart}
                                                    alt='heart'
                                                />
                                            )

                                    )
                                }

                            </div>
                            <div className='web'>
                                <ShareButton />
                            </div>
                            <div className='mobile'>
                                <div className='ws-detail-share'
                                    onClick={handleShareClick}
                                >
                                    <img src={Share} alt='share' />
                                </div>
                            </div>

                            <div className='ws-detail-bookmark' onClick={handleShowBookmark}>
                                {
                                    data.is_saved ? (
                                        isBookmarked ? (
                                            <img
                                                onClick={() => savedStory(data.storyID)}
                                                src={Bookmark}
                                                alt='bookmark'
                                            />



                                        ) : (
                                            <img
                                                onClick={() => unsavedStory(data.storyID)}
                                                src={BookmarkFilled}
                                                alt='bookmark'
                                            />
                                        )
                                    ) : (
                                        isBookmarked ?
                                            (
                                                <img
                                                    onClick={() => unsavedStory(data.storyID)}
                                                    src={BookmarkFilled}
                                                    alt='bookmark'
                                                />
                                            ) : (
                                                <img
                                                    onClick={() => savedStory(data.storyID)}
                                                    src={Bookmark}
                                                    alt='bookmark'
                                                />
                                            )

                                    )
                                }
                            </div>




                            {showPopup && (
                                <LoginPop closePopup={handleClosePopup} />

                            )}
                        </div>
                    </div>
                    <div className='ws-detail-storyimage'>
                        <YouTube videoId={data.youtube_id} opts={opts}
                            onReady={onReady}
                        />
                    </div>
                    <div className='vs-detail-title'>{data.title}</div>
                    <div className='web'>
                        <div className='vs-detail-authorcdate'>
                            <div className='vs-author_image'>
                                <img src={data.userImage} alt='AuthorImage' />
                            </div>
                            <div>
                                <div className='vs-detail-author' onClick={() => authorview(data.authorUserID, data.authorName)}>{data.authorName}</div>

                                <div className='ws-detail-cdate'>Author</div>
                            </div>
                            <div className='vs-viewprofile' onClick={() => authorview(data.authorUserID, data.authorName)}>
                                View Profile
                            </div>

                            <div className='vs-detail-viewsratebox'>
                                <div className='vs-detail-viewsbox'>
                                    <div className='ws-detail-viewstext'>
                                        {data.views}
                                    </div>
                                    <div className='ws-detail-views'>
                                        Views
                                    </div>
                                </div>
                                <div className='vs-detail-ratebox'>
                                    <div className='vs-ratestar'>
                                        <img src={StarRate} alt='starrate' />
                                    </div>
                                    <div className='ws-detail-ratetext'>
                                        {Number(data.rating).toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='mobile'>
                        <div className='vs-detail-authorcdate'>
                            <div className='vs-author_image'>
                                <img src={data.userImage} alt='AuthorImage' />
                            </div>
                            <div>
                                <div className='vs-detail-author' onClick={() => authorview(data.authorUserID, data.authorName)}>{data.authorName}</div>

                                <div className='ws-detail-cdate'>Author</div>
                            </div>
                            <div className='vs-viewprofile' onClick={() => authorview(data.authorUserID, data.authorName)}>
                                View Profile
                            </div>


                        </div>
                        <div className='vs-detail-viewsratebox'>
                            <div className='vs-detail-viewsbox'>
                                <div className='ws-detail-viewstext'>
                                    {data.views}
                                </div>
                                <div className='ws-detail-views'>
                                    Views
                                </div>
                            </div>
                            <div className='vs-detail-ratebox'>
                                <div className='vs-ratestar'>
                                    <img src={StarRate} alt='starrate' />
                                </div>
                                <div className='ws-detail-ratetext'>
                                    {Number(data.rating).toFixed(2)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='vs-detail-summary'>{data.summary}</div>

                    <div className='vs-detail-views-rate'>

                        <div className='vs-detail-rate-story'>
                            <div className='ws-detail-rating'>
                                Rate Story
                            </div>
                            {(authToken == null || authToken === "no token") ?
                                <div className='ws-detail-ratestar'

                                >

                                    {filledStars.map((isFilled, index) => (
                                        <img

                                            key={index}
                                            src={isFilled ? StarFill : Star}
                                            alt={`Star ${index + 1}`}
                                            onClick={handleShowSignup}
                                        />
                                    ))}
                                </div>
                                :
                                <div className='ws-detail-ratestar'

                                >

                                    {filledStars.map((isFilled, index) => (
                                        <img

                                            key={index}
                                            src={isFilled ? StarFill : Star}
                                            alt={`Star ${index + 1}`}
                                            onClick={() => toggleStar(index)}
                                        />
                                    ))}
                                </div>
                            }
                        </div>

                    </div>
                    {/* ListView */}
                    <div className='ws-stories-box-align-bottom' id="divTwo" >
                        <div className='vs-stories-storiescount'>
                            More video stories like this
                            {/* Stories {firstAndLastStoriesNumber} out of {totalStories} */}
                        </div>


                        {loading ? ( // Conditionally render the loading screen or data

                            <div className='loading'>
                                <div className='load_gif'><img src={LoadingGif} alt='d' /></div>
                            </div>
                        ) : (
                            stories.map((story, i) => {
                                return (
                                    <div key={i}>
                                        <div className='web'>

                                            <div className='vs-stories-box' key={i} onClick={() => handleStoryClick(story.storyID)}>
                                                <div className='as-audio-btn' onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                >
                                                    <div className='as-audio-img' >
                                                        <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                    </div>
                                                    <div className='as-audio-player-playbtn'>
                                                        <div>

                                                            {/* <div>
                                                                <div className='as-audio-play'>
                                                                    <img src={PlayButtonbtn} alt='Play' />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='ws-stories-text'>
                                                    <div className='ws-stories-title-img' >
                                                        <div className='ws-stories-box-title'
                                                            onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                        >

                                                            {story.title}
                                                        </div>
                                                        <div className='ws-stories-img-hsb' >

                                                            <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                {
                                                                    story.favouriteStory ? (
                                                                        heartedStories[i] ? (
                                                                            <img
                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                src={Heart}
                                                                                alt='heart'
                                                                            />

                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                src={HeartFilled}
                                                                                alt='heart'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        heartedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />
                                                                            )

                                                                    )
                                                                }


                                                            </div>
                                                            <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                {
                                                                    story.is_saved ? (
                                                                        bookmarkedStories[i] ? (
                                                                            <img
                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                src={Bookmark}
                                                                                alt='bookmark'
                                                                            />



                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                src={BookmarkFilled}
                                                                                alt='bookmark'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        bookmarkedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )

                                                                    )
                                                                }
                                                            </div>



                                                        </div>
                                                        {showPopup && (
                                                            <div className="pop-up-overlay">
                                                                <div className="pop-up-box">
                                                                    <p>Login first</p>
                                                                    <button onClick={handleClosePopup}>Close</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='ws-stories-box-auth-date'
                                                    >
                                                        <div className='ws-stories-box-author'
                                                            onClick={handleDetailView.bind(null, story.storyID, story.title)}  >By {story.authorName}&nbsp;-&nbsp;</div>
                                                        <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                    </div>
                                                    <div
                                                        className="ws-stories-box-wrapper"
                                                        onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                        style={{ padding: '45px 0', cursor: 'pointer' }}
                                                    >
                                                        <div className="ws-stories-box-summary">
                                                            {story.summary}
                                                        </div>
                                                    </div>


                                                    <div className='ws-story-box-viewrate'
                                                        onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                    >
                                                        <div className='ws-stories-box-viewbox'>
                                                            <div className='ws-story-view'>{story.views}</div>
                                                            <div className='ws-story-viewtext'>views</div>
                                                        </div>
                                                        <div className='ws-stories-box-viewbox'>
                                                            <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                            <div className='ws-story-ratetext'>rating</div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className='mobile'>
                                            <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                                <div className='as-audio-btn' onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                >
                                                    <div className='as-audio-img' >
                                                        <img src={story.storyThumbnailImage} alt='thumbnail' />

                                                    </div>
                                                    <div className='as-audio-player-playbtn'>
                                                        <div>

                                                            <div><div className='as-audio-play'>
                                                                <img src={PlayButtonbtn} alt='Play' />
                                                            </div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='ws-stories-text' >
                                                    <div className='ws-stories-title-img'>
                                                        <div className='ws-stories-box-title' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                        >
                                                            {story.title}
                                                        </div>
                                                        <div className='ws-stories-img-hsb'>

                                                            <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                {
                                                                    story.favouriteStory ? (
                                                                        heartedStories[i] ? (
                                                                            <img
                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                src={Heart}
                                                                                alt='heart'
                                                                            />

                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                src={HeartFilled}
                                                                                alt='heart'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        heartedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />
                                                                            )

                                                                    )
                                                                }


                                                            </div>

                                                            <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                {
                                                                    story.is_saved ? (
                                                                        bookmarkedStories[i] ? (
                                                                            <img
                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                src={Bookmark}
                                                                                alt='bookmark'
                                                                            />



                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                src={BookmarkFilled}
                                                                                alt='bookmark'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        bookmarkedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )

                                                                    )
                                                                }
                                                            </div>

                                                        </div>
                                                        {showPopup && (
                                                            <div className="pop-up-overlay">
                                                                <div className="pop-up-box">
                                                                    <p>Login first</p>
                                                                    <button onClick={handleClosePopup}>Close</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='ws-stories-box-auth-date' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                    >
                                                        <div className='ws-stories-box-author'>By {story.authorName} - </div>
                                                        <div className='ws-stories-box-date'>{formatDateMob(story.creationDate)}</div>
                                                    </div>
                                                    <div className='ws-stories-box-summary' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                    >
                                                        {story.summary}
                                                    </div>
                                                    <div className='ws-stories-box-view-rate'
                                                    >
                                                        <div className='ws-stories-box-viewbox'>
                                                            <div className='ws-story-view'>{story.views}</div>
                                                            <div className='ws-story-viewtext'>views</div>
                                                        </div>
                                                        <div className='ws-stories-box-ratebox'>
                                                            <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                            <div className='ws-story-ratetext'>rating</div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )

                            })
                        )}
                    </div>
                    {showSignup && (
                        <div className="speech-bubble-container">
                            <div className="speech-bubble" onClick={handleCloseSignup}>

                                <div className="box-content">

                                    <span className='ws-rating-signup' >SignUp</span>&nbsp;
                                    to rate the story.

                                </div>
                            </div>


                        </div>
                    )}


                </div>



            )}

            {/* <div className='ws-stories-detailview-ads'>
                <div className='ws-stories-detailview-adsBlock'>
                    <AdsDetailView />
                </div>
            </div> */}
        </div>
    )
}

export default DetailedView
